import React, { useEffect, useState } from 'react'
import './Navbar.css'
import logo from '../Pics/Group 1000002918.svg'
import whitelogo from '../Pics/Group 1000002925.svg'

import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

function Navbar() {
    const location = useLocation()
    const [navlogo, setnavlogo] = useState(logo);
    const [colordark, setcolordark] = useState(false);
    const [activeStatus, setactiveStatus] = useState("home");
    const [toggle, settoggle] = useState(true);
    const [open, setopen] = useState(true);

    useEffect(() => {
        if (location.pathname == '/contact' || location.pathname == '/calculatetax') {
            setcolordark(true)
            setnavlogo(whitelogo)

        } else {
            setnavlogo(logo)
            setcolordark(false)
        }
    }, [location]);

    return (
        <div>
            <div className='container-fluid d-lg-flex justify-content-center d-none'>
                <div className='container position-absolute'>

                    <nav class="navbar navbar-expand-lg navbar-div1">
                        <Link class="navbar-brand" to="/"><img src={navlogo} alt="" srcset="" /></Link>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse " id="navbarNavDropdown">
                            <ul class="navbar-nav nav-ul1 ml-auto ">
                                <li class="nav-item active">
                                    <Link className={`${colordark ? "nav-link dark-nav-text1" : "nav-link nav-text1"
                                        }`} to="/">Home </Link>
                                </li>
                                <li class="nav-item">
                                    <Link className={`${colordark ? "nav-link dark-nav-text1" : "nav-link nav-text1"
                                        }`} to="/about">About Us</Link>
                                </li>
                                <li class="nav-item">
                                    <Link className={`${colordark ? "nav-link dark-nav-text1" : "nav-link nav-text1"
                                        }`} to="/services">Services</Link>
                                </li>
                                {/* <li class="nav-item">
                                <Link className={`${colordark ? "nav-link dark-nav-text1" : "nav-link nav-text1"
                                    }`} href="#">Client Reviews</Link>
                            </li> */}
                                <li class="nav-item">
                                    <Link className={`${colordark ? "nav-link dark-nav-text1" : "nav-link nav-text1"
                                        }`} to="/contact">Contact Us</Link>
                                </li>
                            </ul>
                        </div>

                        <div className='d-md-flex d-none nav-mainbtns-div1  '>
                            <div>
                                <Link to='/calculatetax' > <div className={`${!colordark ? "nav-btn-1" : "dark-nav-btn-1"
                                    }`}>Calculate Tax</div></Link>
                            </div>





                            <div className='position-relative'>
                                <a class="nav-link dropdown-toggle p-0" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                    <div className='nav-btn-2'>Download App</div>
                                </a>
                                <div class="dropdown-menu nav-dropdown-div1 ">


                                    <Link class="dropdown-item" to='/'>
                                        <div className='nav-calculate-app-div1 d-flex'>
                                            <div >
                                                <div className='calculate-app-icon-div1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                                                        <path d="M18.1058 8.86398C15.1036 7.18482 10.2851 4.48849 2.81113 0.303655C2.30723 -0.0291271 1.72748 -0.0727376 1.22656 0.0968083L14.0498 12.92L18.1058 8.86398Z" fill="#32BBFF" />
                                                        <path d="M1.23225 0.0969238C1.13836 0.128723 1.047 0.167084 0.959632 0.21342C0.406476 0.512637 0 1.08841 0 1.81661V24.0236C0 24.7518 0.406425 25.3276 0.959632 25.6268C1.04685 25.6731 1.13816 25.7116 1.23195 25.7436L14.0554 12.9201L1.23225 0.0969238Z" fill="#32BBFF" />
                                                        <path d="M14.0501 12.9199L1.22656 25.7434C1.72763 25.9142 2.30739 25.8739 2.81143 25.5363C10.0596 21.4776 14.8238 18.8132 17.8607 17.1198C17.9452 17.0723 18.0281 17.0258 18.1099 16.9798L14.0501 12.9199Z" fill="#32BBFF" />
                                                        <path d="M0 13.3071V23.6976C0 24.3791 0.375906 24.9179 0.887572 25.1978C0.968244 25.2411 1.0527 25.2772 1.13944 25.3071L13 13.3071H0Z" fill="#2C9FD9" />
                                                        <path d="M2.84074 0.608716C2.24766 0.217067 1.54826 0.228224 1 0.519209L13.787 13.3071L18 9.09385C15.0245 7.42945 10.2486 4.7568 2.84074 0.608716Z" fill="#29CC5E" />
                                                        <path d="M13.7842 13.3071L1 25.1096C1.54819 25.3781 2.24738 25.3936 2.84033 25.027C10.0227 21.3139 14.7437 18.8765 17.753 17.3272C17.8368 17.2838 17.9189 17.2413 18 17.1992L13.7842 13.3071Z" fill="#D93F21" />
                                                        <path d="M24 12.8049C24 12.1453 23.6515 11.4796 22.9608 11.1105C22.9608 11.1105 21.669 10.4222 17.7096 8.30713L13 12.8049L17.7142 17.3071C21.6302 15.2073 22.9607 14.4993 22.9607 14.4993C23.6515 14.1302 24 13.4645 24 12.8049Z" fill="#FFD500" />
                                                        <path d="M22.9608 14.8125C23.6515 14.4846 24 13.8932 24 13.3071H13L17.7142 17.3071C21.6302 15.4416 22.9608 14.8125 22.9608 14.8125Z" fill="#FFAA00" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center '>
                                                <h1 className='calculate-app-icon-text1'>Download Our
                                                    <br />
                                                    Android App</h1>
                                            </div>
                                            <svg className='arrow-svg1' xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path d="M13 1C13 0.447715 12.5523 2.00008e-07 12 -5.28728e-08L3 -6.00782e-07C2.44772 -2.63607e-07 2 0.447715 2 1C2 1.55228 2.44772 2 3 2L11 2L11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10L13 1ZM1.70711 12.7071L12.7071 1.70711L11.2929 0.292893L0.292893 11.2929L1.70711 12.7071Z" fill="black" />
                                            </svg>
                                        </div></Link>
                                    <div class="dropdown-divider"></div>



                                    <Link class="dropdown-item" to='/'>
                                        <div className='nav-calculate-app-div1 d-flex'>
                                            <div >
                                                <div className='calculate-app-icon-div1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" viewBox="0 0 23 28" fill="none">
                                                        <path d="M19.2103 14.8903C19.1781 11.353 22.1346 9.63202 22.2698 9.5519C20.5954 7.13402 18.0002 6.80366 17.0882 6.77732C14.9086 6.55013 12.7943 8.06913 11.684 8.06913C10.5515 8.06913 8.8417 6.79927 6.99892 6.83659C4.62757 6.8728 2.40913 8.23266 1.19243 10.3443C-1.31854 14.65 0.55416 20.9773 2.95986 24.4576C4.16327 26.1632 5.56946 28.0652 7.41003 27.9983C9.2107 27.9258 9.88332 26.8623 12.0563 26.8623C14.2094 26.8623 14.841 27.9983 16.7181 27.9555C18.6507 27.9258 19.8674 26.2433 21.0287 24.5235C22.4193 22.5709 22.9778 20.6459 23 20.5471C22.9557 20.5317 19.2479 19.1291 19.2103 14.8903ZM15.6643 4.48785C16.6328 3.28823 17.2955 1.65619 17.1115 0C15.7098 0.0614623 13.9567 0.960349 12.9472 2.13362C12.0541 3.1675 11.2563 4.86211 11.4624 6.45574C13.037 6.57208 14.6537 5.6688 15.6643 4.48785Z" fill="black" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center '>
                                                <h1 className='calculate-app-icon-text1'>Download Our
                                                    <br />
                                                    IOS App</h1>
                                            </div>
                                            <svg className='arrow-svg1' xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path d="M13 1C13 0.447715 12.5523 2.00008e-07 12 -5.28728e-08L3 -6.00782e-07C2.44772 -2.63607e-07 2 0.447715 2 1C2 1.55228 2.44772 2 3 2L11 2L11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10L13 1ZM1.70711 12.7071L12.7071 1.70711L11.2929 0.292893L0.292893 11.2929L1.70711 12.7071Z" fill="black" />
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

            </div>



















            <div className='d-lg-none d-block'>

                <nav class="navbar mobile-navbar-div1 d-flex d-lg-none  ">
                    <Link
                        class="navbar-brand"
                        to="/"
                    >
                        <img src={navlogo} className="ml-3" width='70' />
                    </Link>
                    <button
                        class="navbar-toggler mobile-nav-toogler"
                        type="button"
                        // data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                        onClick={() => settoggle(!toggle)}
                    >
                        <svg className={`${colordark ? "dark-three-line-svg1" : "three-line-svg1"}`} xmlns="http://www.w3.org/2000/svg" width="36" height="24" viewBox="0 0 36 24" fill="none">
                            <rect x="0.600098" y="0.785645" width="35.4" height="3.04286" rx="1.52143" fill="#141414" />
                            <rect x="0.600098" y="10.8286" width="35.4" height="3.04286" rx="1.52143" fill="#141414" />
                            <rect x="0.600098" y="20.8716" width="35.4" height="3.04286" rx="1.52143" fill="#141414" />
                        </svg>
                    </button>

                    <div
                        class={`${toggle
                            ? "navbar-collapse mobile-navbar-div2"
                            : "navbar-collapse mobile-navbar-div2 show"
                            } `}
                        id="navbarSupportedContent"
                    >
                        <ul class="navbar-nav mx-auto">
                            <li
                                className={`${activeStatus === "home"
                                    ? "nav-item mobile-nav-item active home1"
                                    : "nav-item mobile-nav-item home1"
                                    }`}
                            >
                                <Link className={`${colordark ? "nav-link nav-text1" : "nav-link nav-text1"
                                    }`} to="/" onClick={() => {
                                        settoggle(!toggle);
                                    }}>Home </Link>

                            </li>
                            <li
                                className={`${activeStatus === "about"
                                    ? "nav-item mobile-nav-item active home1"
                                    : "nav-item mobile-nav-item home1"
                                    }`}
                            >
                                <Link className={`${colordark ? "nav-link nav-text1" : "nav-link nav-text1"
                                    }`} to="/about" onClick={() => {
                                        settoggle(!toggle);
                                    }}>About Us </Link>
                            </li>


                            <li
                                className={`${activeStatus === "career"
                                    ? "nav-item mobile-nav-item active home1"
                                    : "nav-item mobile-nav-item home1"
                                    }`}
                            >
                                <Link className={`${colordark ? "nav-link nav-text1" : "nav-link nav-text1"
                                    }`} to="/services" onClick={() => {
                                        settoggle(!toggle);
                                    }}>Services </Link>
                            </li>

                            <li
                                className={`${activeStatus === "contact"
                                    ? "nav-item mobile-nav-item active home1"
                                    : "nav-item mobile-nav-item home1"
                                    }`}
                            >
                                <Link className={`${colordark ? "nav-link nav-text1" : "nav-link nav-text1"
                                    }`} to="/calculatetax" onClick={() => {
                                        settoggle(!toggle);
                                    }}>Calculate Tax </Link>
                            </li>

                            <li
                                className={`${activeStatus === "contact"
                                    ? "nav-item mobile-nav-item active home1"
                                    : "nav-item mobile-nav-item home1"
                                    }`}
                            >
                                <Link className={`${colordark ? "nav-link nav-text1" : "nav-link nav-text1"
                                    }`} to="/contact" onClick={() => {
                                        settoggle(!toggle);
                                    }}>Contact Us </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>



        </div>
    )
}

export default Navbar
