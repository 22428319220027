import React from 'react'
import './About.css'
import aboutimg from '../Pics/image 683.png'
import Cardsection from './Cardsection'
import playbtnsvg from '../Pics/Group 26119.svg'
import Fade from "react-reveal/Fade";
// <Fade left distance="6%" duration={1200}>
import { Link } from 'react-router-dom'


function About() {
    return (
        <div>
            <div className='container-fluid about-banner-div1'>
                <div className='container'>
                    <Fade bottom distance="6%" duration={1200}>
                        <div>
                            <h1 className='about-banner-text1'>Tax & accountancy</h1>
                            <h1 className='about-banner-text2'>Welcome To <br />
                                <span>Crawley</span> Accountants</h1>
                            <p className='about-banner-text3'>Let us handle the accounting so that you can focus on your business <br />
                                Offering you expert business advice and support, delivered by friendly team.</p>




                            <div className='position-relative'>
                                <a class=" dropdown-toggle p-0" href="#" role="button" data-toggle="dropdown" aria-expanded="false">
                                    <div className="about-banner-btn-div1">

                                        <a href="">
                                            <div className="banner-btn-1 for-responsive-banner-btn-div1">
                                                {" "}
                                                Start Now
                                                {/* play store svg */}
                                                <div>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="ml-4"
                                                        width="35"
                                                        height="35"
                                                        viewBox="0 0 35 35"
                                                        fill="none"
                                                    >
                                                        <rect width="35" height="35" fill="white" />
                                                        <path
                                                            d="M24.1058 13.5569C21.1036 11.8777 16.2851 9.18137 8.81113 4.99653C8.30723 4.66374 7.72748 4.62013 7.22656 4.78968L20.0498 17.6129L24.1058 13.5569Z"
                                                            fill="#32BBFF"
                                                        />
                                                        <path
                                                            d="M7.23225 4.78979C7.13836 4.82159 7.047 4.85996 6.95963 4.90629C6.40648 5.20551 6 5.78128 6 6.50948V28.7165C6 29.4447 6.40643 30.0205 6.95963 30.3196C7.04685 30.3659 7.13816 30.4044 7.23195 30.4364L20.0554 17.613L7.23225 4.78979Z"
                                                            fill="#32BBFF"
                                                        />
                                                        <path
                                                            d="M20.0501 17.6128L7.22656 30.4362C7.72763 30.607 8.30739 30.5668 8.81143 30.2291C16.0596 26.1704 20.8238 23.5061 23.8607 21.8126C23.9452 21.7651 24.0281 21.7187 24.1099 21.6727L20.0501 17.6128Z"
                                                            fill="#32BBFF"
                                                        />
                                                        <path
                                                            d="M6 18V28.3905C6 29.0719 6.37591 29.6107 6.88757 29.8907C6.96824 29.934 7.0527 29.9701 7.13944 30L19 18H6Z"
                                                            fill="#2C9FD9"
                                                        />
                                                        <path
                                                            d="M8.84074 5.30159C8.24766 4.90994 7.54826 4.9211 7 5.21208L19.787 18L24 13.7867C21.0245 12.1223 16.2486 9.44967 8.84074 5.30159Z"
                                                            fill="#29CC5E"
                                                        />
                                                        <path
                                                            d="M19.7842 18L7 29.8025C7.54819 30.071 8.24738 30.0865 8.84033 29.7198C16.0227 26.0068 20.7437 23.5693 23.753 22.0201C23.8368 21.9767 23.9189 21.9342 24 21.8921L19.7842 18Z"
                                                            fill="#D93F21"
                                                        />
                                                        <path
                                                            d="M30 17.4978C30 16.8382 29.6515 16.1725 28.9608 15.8034C28.9608 15.8034 27.669 15.115 23.7096 13L19 17.4978L23.7142 22C27.6302 19.9002 28.9607 19.1921 28.9607 19.1921C29.6515 18.823 30 18.1574 30 17.4978Z"
                                                            fill="#FFD500"
                                                        />
                                                        <path
                                                            d="M28.9608 19.5054C29.6515 19.1774 30 18.586 30 18H19L23.7142 22C27.6302 20.1344 28.9608 19.5054 28.9608 19.5054Z"
                                                            fill="#FFAA00"
                                                        />
                                                    </svg>
                                                    {/* apple store logo */}
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="ml-2"
                                                        width="35"
                                                        height="35"
                                                        viewBox="0 0 35 35"
                                                        fill="none"
                                                    >
                                                        <rect width="35" height="35" fill="white" />
                                                        <path
                                                            d="M25.2103 17.8903C25.1781 14.353 28.1346 12.632 28.2698 12.5519C26.5954 10.134 24.0002 9.80366 23.0882 9.77732C20.9086 9.55013 18.7943 11.0691 17.684 11.0691C16.5515 11.0691 14.8417 9.79927 12.9989 9.83659C10.6276 9.8728 8.40913 11.2327 7.19243 13.3443C4.68146 17.65 6.55416 23.9773 8.95986 27.4576C10.1633 29.1632 11.5695 31.0652 13.41 30.9983C15.2107 30.9258 15.8833 29.8623 18.0563 29.8623C20.2094 29.8623 20.841 30.9983 22.7181 30.9555C24.6507 30.9258 25.8674 29.2433 27.0287 27.5235C28.4193 25.5709 28.9778 23.6459 29 23.5471C28.9557 23.5317 25.2479 22.1291 25.2103 17.8903ZM21.6643 7.48785C22.6328 6.28823 23.2955 4.65619 23.1115 3C21.7098 3.06146 19.9567 3.96035 18.9472 5.13362C18.0541 6.1675 17.2563 7.86211 17.4624 9.45574C19.037 9.57208 20.6537 8.6688 21.6643 7.48785Z"
                                                            fill="black"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </a>
                                <div class="dropdown-menu home-dropdown-div1 " x-placement='bottom-start' >


                                    <Link class="dropdown-item" to='/'>
                                        <div className='home-calculate-app-div1 d-flex'>
                                            <div >
                                                <div className='calculate-app-icon-div1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                                                        <path d="M18.1058 8.86398C15.1036 7.18482 10.2851 4.48849 2.81113 0.303655C2.30723 -0.0291271 1.72748 -0.0727376 1.22656 0.0968083L14.0498 12.92L18.1058 8.86398Z" fill="#32BBFF" />
                                                        <path d="M1.23225 0.0969238C1.13836 0.128723 1.047 0.167084 0.959632 0.21342C0.406476 0.512637 0 1.08841 0 1.81661V24.0236C0 24.7518 0.406425 25.3276 0.959632 25.6268C1.04685 25.6731 1.13816 25.7116 1.23195 25.7436L14.0554 12.9201L1.23225 0.0969238Z" fill="#32BBFF" />
                                                        <path d="M14.0501 12.9199L1.22656 25.7434C1.72763 25.9142 2.30739 25.8739 2.81143 25.5363C10.0596 21.4776 14.8238 18.8132 17.8607 17.1198C17.9452 17.0723 18.0281 17.0258 18.1099 16.9798L14.0501 12.9199Z" fill="#32BBFF" />
                                                        <path d="M0 13.3071V23.6976C0 24.3791 0.375906 24.9179 0.887572 25.1978C0.968244 25.2411 1.0527 25.2772 1.13944 25.3071L13 13.3071H0Z" fill="#2C9FD9" />
                                                        <path d="M2.84074 0.608716C2.24766 0.217067 1.54826 0.228224 1 0.519209L13.787 13.3071L18 9.09385C15.0245 7.42945 10.2486 4.7568 2.84074 0.608716Z" fill="#29CC5E" />
                                                        <path d="M13.7842 13.3071L1 25.1096C1.54819 25.3781 2.24738 25.3936 2.84033 25.027C10.0227 21.3139 14.7437 18.8765 17.753 17.3272C17.8368 17.2838 17.9189 17.2413 18 17.1992L13.7842 13.3071Z" fill="#D93F21" />
                                                        <path d="M24 12.8049C24 12.1453 23.6515 11.4796 22.9608 11.1105C22.9608 11.1105 21.669 10.4222 17.7096 8.30713L13 12.8049L17.7142 17.3071C21.6302 15.2073 22.9607 14.4993 22.9607 14.4993C23.6515 14.1302 24 13.4645 24 12.8049Z" fill="#FFD500" />
                                                        <path d="M22.9608 14.8125C23.6515 14.4846 24 13.8932 24 13.3071H13L17.7142 17.3071C21.6302 15.4416 22.9608 14.8125 22.9608 14.8125Z" fill="#FFAA00" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center '>
                                                <h1 className='calculate-app-icon-text1'>Download Our
                                                    <br />
                                                    Android App</h1>
                                            </div>
                                            <svg className='arrow-svg1' xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path d="M13 1C13 0.447715 12.5523 2.00008e-07 12 -5.28728e-08L3 -6.00782e-07C2.44772 -2.63607e-07 2 0.447715 2 1C2 1.55228 2.44772 2 3 2L11 2L11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10L13 1ZM1.70711 12.7071L12.7071 1.70711L11.2929 0.292893L0.292893 11.2929L1.70711 12.7071Z" fill="black" />
                                            </svg>
                                        </div></Link>
                                    <div class="dropdown-divider"></div>



                                    <Link class="dropdown-item" to='/'>
                                        <div className='home-calculate-app-div1 d-flex'>
                                            <div >
                                                <div className='calculate-app-icon-div1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" viewBox="0 0 23 28" fill="none">
                                                        <path d="M19.2103 14.8903C19.1781 11.353 22.1346 9.63202 22.2698 9.5519C20.5954 7.13402 18.0002 6.80366 17.0882 6.77732C14.9086 6.55013 12.7943 8.06913 11.684 8.06913C10.5515 8.06913 8.8417 6.79927 6.99892 6.83659C4.62757 6.8728 2.40913 8.23266 1.19243 10.3443C-1.31854 14.65 0.55416 20.9773 2.95986 24.4576C4.16327 26.1632 5.56946 28.0652 7.41003 27.9983C9.2107 27.9258 9.88332 26.8623 12.0563 26.8623C14.2094 26.8623 14.841 27.9983 16.7181 27.9555C18.6507 27.9258 19.8674 26.2433 21.0287 24.5235C22.4193 22.5709 22.9778 20.6459 23 20.5471C22.9557 20.5317 19.2479 19.1291 19.2103 14.8903ZM15.6643 4.48785C16.6328 3.28823 17.2955 1.65619 17.1115 0C15.7098 0.0614623 13.9567 0.960349 12.9472 2.13362C12.0541 3.1675 11.2563 4.86211 11.4624 6.45574C13.037 6.57208 14.6537 5.6688 15.6643 4.48785Z" fill="black" />
                                                    </svg>
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-center '>
                                                <h1 className='calculate-app-icon-text1'>Download Our
                                                    <br />
                                                    IOS App</h1>
                                            </div>
                                            <svg className='arrow-svg1' xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                                <path d="M13 1C13 0.447715 12.5523 2.00008e-07 12 -5.28728e-08L3 -6.00782e-07C2.44772 -2.63607e-07 2 0.447715 2 1C2 1.55228 2.44772 2 3 2L11 2L11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10L13 1ZM1.70711 12.7071L12.7071 1.70711L11.2929 0.292893L0.292893 11.2929L1.70711 12.7071Z" fill="black" />
                                            </svg>
                                        </div>
                                    </Link>
                                </div>
                            </div>



                        </div>
                    </Fade>
                </div>
            </div>



            {/* section no 2 ######### */}


            <div className='container-fluid'>
                <div className='container'>
                    <div className='about-pic-div1'>
                        <img src={aboutimg} className='img-fluid' alt="" />

                        <div className='about-pic-btn-div1'>
                            <a href="">
                                <img src={playbtnsvg} className='img-fluid' alt="" />
                            </a>

                        </div>
                    </div>
                </div>
            </div>


            {/* section no ##### 3  */}

            <div className='container-fluid account-sec3-div1'>
                <div className='container'>
                    <div>
                        <Fade top distance="3%" >
                            <h1 className='account-sec3-text1'>Accountants specialising in small businesses and self-employed individuals.</h1>
                        </Fade>
                        <Fade top distance="3%" >
                            <p className='account-sec3-text2'>Crawley Accountants is a firm of Financial Accountants committed to providing first-class accounts and taxation service at competitive prices to small businesses and individuals.</p>
                        </Fade>
                        <Fade top distance="3%" >
                            <p className='account-sec3-text2'>We understand the value of your precious time and money; therefore, we are here to assist you in solving all the big and small tax-related issues which you face daily or frequently with regards to your accounts and business.</p>
                        </Fade>
                        <Fade top distance="3%" >
                            <p className='account-sec3-text2'>We believe in adopting a proactive approach for our clients. We take care of their financial matters and accounts by providing them with accurate plans and guidelines to increase their turnovers, tax efficiency and preventing them from being fined due to lack of knowledge and expertise.</p>
                        </Fade>
                    </div>
                </div>
            </div>

            <Cardsection></Cardsection>
        </div>
    )
}

export default About
