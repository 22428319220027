import React from 'react'
import './CalculateTax.css'
import Cardsection from './Cardsection'
import linesvg8 from '../Pics/Group (8).svg'
import linesvg9 from '../Pics/Group (9).svg'


function CalculateTax() {
    return (
        <div>
            <div className='container-fluid calculate-section1-div1 '>
                <div>
                    <img src={linesvg8} className='line-svg-8' alt="" />
                </div>
                <div>
                    <img src={linesvg9} className='line-svg-9' alt="" />
                </div>

                <div className='container calculate-section1-div2'>
                    <div>
                        <h1 className='calculate-section1-text1'>CALCULATE <span>TAX</span></h1>
                        <p className='calculate-section1-text2'>Let us handle the accounting so that you can focus on your business <br className='d-md-block d-none'/>
                            Offering you expert business advice and support, delivered by friendly team.</p>
                    </div>

                    <div className='calculate-form-div1'>
                        <div className=''>
                            <form className="contact-form" >
                                <div class="row">
                                    <div class="col-lg-6 ">
                                        <div class="d-flex calculate-input-field-div1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.96094 6C4.85637 6 3.96094 6.89543 3.96094 8V17C3.96094 18.1046 4.85637 19 5.96094 19H19.9609C21.0655 19 21.9609 18.1046 21.9609 17V8C21.9609 6.89543 21.0655 6 19.9609 6H5.96094ZM20.1213 8.14443C19.9249 7.77972 19.4701 7.64327 19.1054 7.83965L12.9609 11.1482L6.81651 7.83965C6.45181 7.64327 5.99696 7.77972 5.80058 8.14443C5.60421 8.50913 5.74066 8.96397 6.10536 9.16035L12.6054 12.6604C12.8273 12.7799 13.0945 12.7799 13.3165 12.6604L19.8165 9.16035C20.1812 8.96397 20.3177 8.50913 20.1213 8.14443Z" fill="#292668" />
                                            </svg>
                                            <input
                                                type="email"
                                                name="email"
                                                class="form-control calculate-input-field"
                                                placeholder="Email Address"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mt-md-0 mt-3">
                                        <div class="d-flex calculate-input-field-div1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0851 14.1427L9.88108 11.9387C9.27236 11.33 9.12145 10.4 9.50644 9.63003L9.63132 9.38027C10.0163 8.6103 9.8654 7.68035 9.25668 7.07163L6.52387 4.33883C6.32861 4.14357 6.01203 4.14357 5.81677 4.33883C5.77876 4.37683 5.74714 4.42071 5.72311 4.46878L4.61913 6.67673C3.77458 8.36584 4.10563 10.4059 5.44098 11.7412L11.4398 17.74C12.8933 19.1936 15.0434 19.7011 16.9935 19.0511L19.408 18.2463C19.6699 18.1589 19.8115 17.8758 19.7242 17.6138C19.6997 17.5402 19.6583 17.4733 19.6034 17.4184L16.9521 14.7671C16.3434 14.1584 15.4135 14.0075 14.6435 14.3925L14.3937 14.5173C13.6238 14.9023 12.6938 14.7514 12.0851 14.1427Z" fill="#292668" />
                                            </svg>
                                            <input
                                                type="number"
                                                name="phone"
                                                class="form-control calculate-input-field"
                                                placeholder="Phone Number"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mt-md-4 mt-3">
                                        <div class="d-flex calculate-input-field-div1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11Z" fill="#292668" />
                                                <path d="M3.00065 20.1992C3.38826 15.4265 7.26191 13 11.9833 13C16.7712 13 20.7049 15.2932 20.9979 20.2C21.0096 20.3955 20.9979 21 20.2467 21C16.5411 21 11.0347 21 3.7275 21C3.47671 21 2.97954 20.4592 3.00065 20.1992Z" fill="#292668" />
                                            </svg>
                                            <input
                                                type="text"
                                                name="fname"
                                                class="form-control calculate-input-field"
                                                placeholder="First Name"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-lg-6 mt-md-4 mt-3">
                                        <div class="d-flex calculate-input-field-div1">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path opacity="0.3" d="M12 11C9.79086 11 8 9.20914 8 7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7C16 9.20914 14.2091 11 12 11Z" fill="#292668" />
                                                <path d="M3.00065 20.1992C3.38826 15.4265 7.26191 13 11.9833 13C16.7712 13 20.7049 15.2932 20.9979 20.2C21.0096 20.3955 20.9979 21 20.2467 21C16.5411 21 11.0347 21 3.7275 21C3.47671 21 2.97954 20.4592 3.00065 20.1992Z" fill="#292668" />
                                            </svg>
                                            <input
                                                type="text"
                                                name="lname"
                                                class="form-control calculate-input-field"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>
                                    <div className=' col-12 calculate-btn1-div1'>
                                        <button className='col-12 calculate-btn1'>Start Now</button>
                                    </div>

                                </div>
                            </form>
                        </div>

                        <div className='d-flex calculate-or-div1'>
                            <div className=' calculate-or-line1'></div>
                            <h1 className=' calculate-or-text1'>Or</h1>
                            <div className=' calculate-or-line1'></div>
                        </div>


                        <div className='col-12 calculate-app-maindiv1'>
                            <div className='row'>
                                <div className='col-lg-6 col-12'>
                                    <div className='calculate-app-div1 d-flex'>
                                        <div >
                                            <div className='calculate-app-icon-div1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26" fill="none">
                                                    <path d="M18.1058 8.86398C15.1036 7.18482 10.2851 4.48849 2.81113 0.303655C2.30723 -0.0291271 1.72748 -0.0727376 1.22656 0.0968083L14.0498 12.92L18.1058 8.86398Z" fill="#32BBFF" />
                                                    <path d="M1.23225 0.0969238C1.13836 0.128723 1.047 0.167084 0.959632 0.21342C0.406476 0.512637 0 1.08841 0 1.81661V24.0236C0 24.7518 0.406425 25.3276 0.959632 25.6268C1.04685 25.6731 1.13816 25.7116 1.23195 25.7436L14.0554 12.9201L1.23225 0.0969238Z" fill="#32BBFF" />
                                                    <path d="M14.0501 12.9199L1.22656 25.7434C1.72763 25.9142 2.30739 25.8739 2.81143 25.5363C10.0596 21.4776 14.8238 18.8132 17.8607 17.1198C17.9452 17.0723 18.0281 17.0258 18.1099 16.9798L14.0501 12.9199Z" fill="#32BBFF" />
                                                    <path d="M0 13.3071V23.6976C0 24.3791 0.375906 24.9179 0.887572 25.1978C0.968244 25.2411 1.0527 25.2772 1.13944 25.3071L13 13.3071H0Z" fill="#2C9FD9" />
                                                    <path d="M2.84074 0.608716C2.24766 0.217067 1.54826 0.228224 1 0.519209L13.787 13.3071L18 9.09385C15.0245 7.42945 10.2486 4.7568 2.84074 0.608716Z" fill="#29CC5E" />
                                                    <path d="M13.7842 13.3071L1 25.1096C1.54819 25.3781 2.24738 25.3936 2.84033 25.027C10.0227 21.3139 14.7437 18.8765 17.753 17.3272C17.8368 17.2838 17.9189 17.2413 18 17.1992L13.7842 13.3071Z" fill="#D93F21" />
                                                    <path d="M24 12.8049C24 12.1453 23.6515 11.4796 22.9608 11.1105C22.9608 11.1105 21.669 10.4222 17.7096 8.30713L13 12.8049L17.7142 17.3071C21.6302 15.2073 22.9607 14.4993 22.9607 14.4993C23.6515 14.1302 24 13.4645 24 12.8049Z" fill="#FFD500" />
                                                    <path d="M22.9608 14.8125C23.6515 14.4846 24 13.8932 24 13.3071H13L17.7142 17.3071C21.6302 15.4416 22.9608 14.8125 22.9608 14.8125Z" fill="#FFAA00" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center '>
                                            <h1 className='calculate-app-icon-text1'>Download Our
                                                <br />
                                                Android App</h1>
                                        </div>
                                        <svg className='arrow-svg1' xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                            <path d="M13 1C13 0.447715 12.5523 2.00008e-07 12 -5.28728e-08L3 -6.00782e-07C2.44772 -2.63607e-07 2 0.447715 2 1C2 1.55228 2.44772 2 3 2L11 2L11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10L13 1ZM1.70711 12.7071L12.7071 1.70711L11.2929 0.292893L0.292893 11.2929L1.70711 12.7071Z" fill="black" />
                                        </svg>
                                    </div>
                                </div>


                                <div className='col-lg-6 col-12 mt-lg-0 mt-3'>
                                     <div className='calculate-app-div1 d-flex'>
                                        <div >
                                            <div className='calculate-app-icon-div1'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" viewBox="0 0 23 28" fill="none">
                                                    <path d="M19.2103 14.8903C19.1781 11.353 22.1346 9.63202 22.2698 9.5519C20.5954 7.13402 18.0002 6.80366 17.0882 6.77732C14.9086 6.55013 12.7943 8.06913 11.684 8.06913C10.5515 8.06913 8.8417 6.79927 6.99892 6.83659C4.62757 6.8728 2.40913 8.23266 1.19243 10.3443C-1.31854 14.65 0.55416 20.9773 2.95986 24.4576C4.16327 26.1632 5.56946 28.0652 7.41003 27.9983C9.2107 27.9258 9.88332 26.8623 12.0563 26.8623C14.2094 26.8623 14.841 27.9983 16.7181 27.9555C18.6507 27.9258 19.8674 26.2433 21.0287 24.5235C22.4193 22.5709 22.9778 20.6459 23 20.5471C22.9557 20.5317 19.2479 19.1291 19.2103 14.8903ZM15.6643 4.48785C16.6328 3.28823 17.2955 1.65619 17.1115 0C15.7098 0.0614623 13.9567 0.960349 12.9472 2.13362C12.0541 3.1675 11.2563 4.86211 11.4624 6.45574C13.037 6.57208 14.6537 5.6688 15.6643 4.48785Z" fill="black" />
                                                </svg>
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center '>
                                            <h1 className='calculate-app-icon-text1'>Download Our
                                                <br />
                                                IOS App</h1>
                                        </div>
                                        <svg className='arrow-svg1' xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
                                            <path d="M13 1C13 0.447715 12.5523 2.00008e-07 12 -5.28728e-08L3 -6.00782e-07C2.44772 -2.63607e-07 2 0.447715 2 1C2 1.55228 2.44772 2 3 2L11 2L11 10C11 10.5523 11.4477 11 12 11C12.5523 11 13 10.5523 13 10L13 1ZM1.70711 12.7071L12.7071 1.70711L11.2929 0.292893L0.292893 11.2929L1.70711 12.7071Z" fill="black" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Cardsection></Cardsection>
        </div>
    )
}

export default CalculateTax
